<script>
  import Layout from "../../layouts/main";
  import test from "@/app.config.json";
  import moment from "moment";
  import axios from "axios";

  //calendar///////
    import FullCalendar from "@fullcalendar/vue";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import bootstrapPlugin from "@fullcalendar/bootstrap";
    import listPlugin from "@fullcalendar/list";
///////////////////////////////////////////////////

  export default {
    page: {title: "EINSTUDIO-Calendar-List",meta: [{name: "calendar"}]},
    components: {
      Layout,FullCalendar
    },data() {
      return {
        title: "MEETINGS",
        items: [{text: "Home"}, {text: "Meetings",active: true}],
        baseUrl:test.baseUrl,user:[],groupId:"",
        calendarOptions: {
            headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            },
            plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            bootstrapPlugin,
            listPlugin,
            ],
            initialView: "dayGridMonth",
            slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
        //  slotDuration: "00:30:00",
        //  slotLabelInterval: "00:30:00",
            nextDayThreshold: '09:00:00',
            themeSystem: "bootstrap",
            eventResizableFromStart: true,
            weekends: true,
           // selectable:(this.user.rol=='teacher'?true:false),
            selectMirror: true,
            dayMaxEvents: false,
            eventDidMount:function (element) 
            {
              if(element.event.extendedProps.type==2)
              {

                var newdate= moment(Date.parse(element.event.start)).format("DD.MM.YYYY HH:mm");
                if(element.el.children[0].children[0] !=undefined){
                  element.el.children[0].children[0].innerText=newdate;
                }
                else
                {
                  element.el.children[0].innerText=newdate
                  element.el.children[2].children[0].children[0].innerText=newdate
                }
 
                //element.el.children[0].children[0].innerText=newdate;
              }
            },
           // initialEvents:this.listData  //[{title:"sdff",start:"2022-12-18 08:00",end:"2022-12-18 10:00"}]
           //eventSources: [{url: test.baseUrl+"/Applications/GetAllByCategories?status=true&pagesize=0&pageindex=0"}]
           events:[],
        }
      };
    },
    created:function(){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadData();
    },
    methods: {
      loadData(){
            
            let loader= this.$loading.show();
            let url=this.baseUrl+"/Applications/GetAllByCategories?status=true&pagesize=0&pageindex=0"
            if(this.user.rol!='admin')
            url+="&userId="+this.user.id

            axios.get(url,{
                headers:{
                    Authorization: `Bearer ${this.user.jwt}`,
                }
            }).then((response)=>{
                loader.hide();
                this.calendarOptions.events = response.data;
            })
        },
        formatDate(date){
        date= moment(date).format('HH:mm');
            return date;

        }
    }
  };
</script>

<template>
  <Layout>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" >
              <template #eventContent="arg">
                <b-tooltip :target="arg.event.id" placement="top" class="tooltip-inner">
                    <div class="card bg-info">
                        <div class="card-header text-dark">
                            <strong>{{arg.event.title}}</strong>
                        </div>
                        <div class="card-body">
                        <ul class="inner-item text-dark">
                            <li> <i class="fa fa-user"></i>&nbsp; <span>{{arg.event.extendedProps.userName}}</span></li>
                            <li> <i class="fa fa-layer-group"></i>&nbsp;<span> {{arg.event.extendedProps.groupName}}</span> </li>
                            <li> <span><a :href="arg.event.extendedProps.applicationLink" target="_blank"><i class="fa fa-link"></i>&nbsp;Application Link</a></span></li>
                            <li><span class="small">{{ arg.event.extendedProps.explanation }}</span></li>
                        </ul>
                        </div>
                        <!-- <div class="card-footer" id="actions" v-if="user.rol=='admin' | user.rol=='teacher'">
                           <a type="button" class="btn btn-sm btn-danger mr-1" @click="DeleteConfirm(arg.event.id)"><i class="fa fa-trash"></i></a>
                           <a type="button" v-b-modal.modal-edit class="btn btn-sm btn-warning" @click="editInitialData(arg.event.id)"><i class="fa fa-edit"></i></a>
                        </div> -->
                    </div>

                </b-tooltip>
                <span  :id="arg.event.id"><i class="fa fa-clock"></i> {{formatDate(arg.event.start)+' '+formatDate(arg.event.end)}}</span>
            </template>
            </FullCalendar>
            </div>
          </div>
        </div>
        </div>
    </div>
  </Layout>
</template>
<style>
ul.inner-item{
    margin:0; padding: 0; list-style: none; text-align: left;
}
.tooltip-inner {
  background-color: initial !important;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #ff0000 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
 border-left-color: #ff0000 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
 border-bottom-color: #ff0000 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
 border-top-color: #000 !important;
}
</style>